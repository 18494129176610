:root {
    --primary-text: rgba(255, 255, 255, 0.9);
    --secondary-text: rgba(255, 255, 255, 0.7);
    --tertiary-text: rgba(255, 255, 255, 0.35);
    --tertiary-text-light: rgba(138, 138, 142, 1);
    --easing-func: cubic-bezier(0.1, 0.6, 0.4, 1);
    --border-color: rgba(255, 255, 255, 0.1);
    --link-color: rgba(11, 132, 255, 1);
    --navbar-height: unset;
    --background: #000000;
}

#__next {
    overflow: hidden;
}

html {
    background: var(--background);
}

html,
* {
    -webkit-text-size-adjust: 100%;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    box-sizing: border-box;
    scroll-behavior: smooth;
}

body,
* {
    font-family: "SF Pro Text", -apple-system, BlinkMacSystemFont, Roboto,
    "Segoe UI", Helvetica, Arial, sans-serif, "Apple Color Emoji",
    "Segoe UI Emoji", "Segoe UI Symbol";
}

body {
    margin: 0;
    padding: 0;
    overflow: hidden scroll;
    color: white;
    height: 200vh;
}

a {
    font-family: "SF Pro Text", -apple-system, BlinkMacSystemFont, Roboto,
    "Segoe UI", Helvetica, Arial, sans-serif, "Apple Color Emoji",
    "Segoe UI Emoji", "Segoe UI Symbol";
    text-decoration: underline;
    color: #0b84ff;
    font-size: 16px;
    font-weight: 400;
    line-height: 18px;
    letter-spacing: 0;
}

ul,
ol,
li {
    margin: 0;
    padding: 0;
    list-style: none;
}

p {
    font-size: 20px;
    font-weight: 400;
    line-height: 28px;
    letter-spacing: -0.2px;
    color: #ffffffcc;
    white-space: pre-line;
}

#__next {
    min-height: 100vh;
    display: flex;
    flex-direction: column;
    position: relative;
}


a:not(.btn) {
    color: #0b84ff;
    text-decoration: underline;
    font-weight: 400;
    font-size: 16px;
    line-height: 18px;
}

a:not(.btn) span {
    -webkit-text-fill-color: white;
}

.navbar-pinned > #__next {
    padding-top: var(--navbar-height);
}


@media (max-width: 1440px) {
    p {
        font-size: 18px;
        line-height: 28px;
    }
}

@media (max-width: 768px) {
    p {
        font-size: 16px;
        line-height: 24px;
        letter-spacing: -0.1px;
    }
}
